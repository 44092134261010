/** @format */

@import "assets/fonts/fonts.scss";

.ProfileContainer {
   background-color: #fff;
   padding: 2%;
}
.ProfileHeader {
   @include font-dmsans;
   font-weight: bold;
   font-size: 1.5rem;
   display: flex;
   justify-content: flex-end;
   gap: 0.5rem;
   position: relative;
   margin-bottom: 2%;
}

.services-text-header {
   font-size: 1.5rem;
}

.services-container {
   // background-color: grey;
   width: 42%;
}

.service-text {
   margin-top: 1rem;
}

.Form {
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin-top: 4%;
}

.FormGroup {
   width: 100%;
   display: grid;
   grid-template-columns: 1fr 1fr;
   align-items: start;
   justify-content: space-between;
   gap: 4%;
}

.ButtonWrapper {
   height: 50%;
   margin-top: 9%;
   padding: 2% 0;
}
.profile-services-main-container {
   // width: 30rem;
   display: flex;
   gap: 1rem;
   justify-content: center;
}
.profile-services-sub-container {
   display: flex;
   margin: 5%;
   margin-left: 0;
   align-items: flex-start;
   // gap: 1.5rem;
   flex-direction: column;
}

// @media screen and (max-width: 1400px) {
//   .profile-services-main-container {
//     width: 24rem;
//   }
// }
// @media only screen and (max-width: 1040px) {
//   .FormGroup {
//     flex-direction: column;
//     justify-content: center;
//     gap: 0.5rem;

//   }}
.geolocations-wrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding: 1%;
   gap: 1rem;
   margin-top: 2rem;
   .geolocations {
      display: flex;
      justify-content: space-between;
      align-items: center;
   }
   .geolocations-label {
      font-size: 1rem;
      font-weight: bold;
      color: rgba(15, 25, 26, 0.5);
   }
   .geolocations-value {
      font-size: 1rem;
      font-weight: bold;
      color: #000;
   }
}
