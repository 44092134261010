.orders-filter-count {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: fit-content;
  min-width: 24px;
  font-size: 14px;
  border-radius: 50%;
  background-color: #2d6b4d;
  color: #fff;
  padding: 1% 2 1% 2%;
}
.orders-filter-clear {
  height: 100%;
  width: 100%;
}
