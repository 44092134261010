/** @format */

@import "assets/fonts/fonts.scss";

.loginContainer {
   flex-direction: row;
   display: flex;
   width: 100vw;
   height: 100vh;
}

.loginScreenContainer {
   align-items: center;
   width: 50%;
}

.logo {
   width: 3.2rem;
   height: 3rem;
}

.logoAndNameContainer {
   display: flex;
   flex-direction: row;
   padding: 2rem;
   align-items: center;
}

.logoNameText {
   margin-left: 1rem;
   font-size: 1.4rem;
   @include font-dmsans;
   color: #2d6b4d;
   font-weight: 500;
}

.phoneNumberText {
   @extend .logoNameText;
   font-weight: bold;
   text-align: center;
}

.loginMainContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.loginInputsContainer {
   flex-direction: column;
   margin-top: 1rem;
   align-items: center;
   justify-content: center;
}

.loginInputWrapper {
   display: flex;
   justify-content: space-between;
   flex-direction: column;
   width: 100%;
}

.termsAndConditions {
   text-align: center;
   font-size: 0.7rem;
}

a:hover {
   cursor: pointer;
}

.tandcText {
   text-decoration-line: underline;
   :hover {
      cursor: pointer;
      color: #4361ee;
   }
}

.ORText {
   width: 100%;
   text-align: center;
   height: 5%;
   margin-top: 1rem;
   margin-bottom: 1rem;
   display: flex;
   align-items: center;
   justify-content: center;
   color: #4361ee;
   font-weight: 500;
}

.loginOptionsContainer {
   height: 30%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1rem;
   justify-content: center;
}

.LoginImageContainer {
   width: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #def7e5;
}

.CheckBoxWrapper {
   width: 100%;
   height: 10%;
}

.LoginImage {
   width: 60%;
   height: 60%;
}

.OtpInputContainer {
   margin-top: 1rem;
   width: 100%;
}

.OtpInputWrapper {
   display: flex;
   margin-top: 1rem;
   width: 100%;
   // background-color: red;
}

.BtnWrapper {
   margin-top: 1rem;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.loginMainWrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 80%;
}

@media screen and (max-width: 1300px) {
   .LoginImage {
      width: 60%;
      height: 50%;
   }
}

@media screen and (max-width: 1000px) {
   .LoginImageContainer {
      display: none;
   }
   .logoAndNameContainer {
      justify-content: center;
      padding: 1rem 2rem 1rem;
      padding-left: 0;
      margin-left: -1rem;
   }
   .loginContainer {
      display: flex;
      justify-content: center;
      width: 100vw;
      height: 100vh;
   }
   .loginScreenContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
   }
   .phoneNumberText {
      margin-left: 0;
   }
}
