@import 'assets/fonts/fonts.scss';

.add-btn-container {
  background-color: #2fbf71;
  color: #fff;
  display: flex;
  width: 4rem;
  height: 1.7rem;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0 2% 0 2%;
  border-radius: 5px;
  border: none;
}

.added {
  background-color: #eaf9f1;
  color: #2fbf71;
  width: min-content;
  border: 1px solid #2fbf71;
}
