/** @format */

.header-sub-text {
   color: #878c8c;
   text-transform: none;
   > span {
      color: #2d6b4d;
      text-transform: none;
      font-weight: bold;
   }
}
.borderbg {
   background-image: url("../../assets/images/bg.png");
   height: fit-content;
   width: fit-content;
   padding: 0.25%;
   border-radius: 8px;
   background-position: center;
   box-shadow: 0px 0px 10px 0px #0000001a;
}

.circleBg {
   background-image: url("../../assets/images/circlebg.png");
   width: fit-content;
   height: fit-content;
   padding: 0.8%;
   border-radius: 50%;
   background-position: center;
}
