@import 'assets/fonts/fonts.scss';

.NoItemsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  height: fit-content;
}
.NoItemsIconWrapper {
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NoItemsInfo {
  display: flex;
  flex-direction: column;
  width: 20rem;
  text-transform: none;
  gap: 0.5rem;
  padding: 1%;
}
.NoItemsTitle {
  color: #000;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  @include font-dmsans;
}
.NoItemsSubTitle {
  color: rgba(15, 25, 26, 0.5);
  font-size: 1rem;
  @include font-dmsans;
  text-align: center;
  font-weight: 500;
}
