.manageSubscriptionMain {
  background-color: #fff;

  .planDetailsBox {
    border: 2px solid #878c8c;
    border-radius: 0.5rem;
    margin-bottom: 3rem;

    > :first-child {
      border-right: 2px solid #878c8c;
    }
  }
  .planName {
    color: #2d6b4d;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: capitalize;
  }

  .activeStatus {
    color: #fff;
    background-color: #2fbf71;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }

  .planKey {
    font-size: 0.8rem;
    color: #878c8c;
  }
}
.fc-red {
  color: #d90429;
}
.fc-blue {
  color: #4361ee;
}
.fc-grey {
  color: #878c8c;
}
.mainButton {
  background-color: #4361ee;
  border-radius: 0.3rem;
  color: #fff;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
}
.nextBilling {
  font-size: 0.8rem;
  color: #878c8c;
}
