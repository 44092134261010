/** @format */

.table-scrollable {
   display: block;
   max-height: 500px; /* Adjust this value to your preference */
   overflow-y: auto;
}

.table-scrollable thead,
.table-scrollable tbody,
.table-scrollable tfoot {
   display: block;
}

/* Additional styling to ensure table column widths are consistent */
.table-scrollable tbody tr,
.table-scrollable thead tr {
   display: table;
   width: 100%;
   table-layout: fixed; /* Optional - depending on your design */
}
