/** @format */

@import "assets/fonts/fonts.scss";

.recommended-container {
   padding: 1%;
}
.recommended-added-items-container-header {
   display: flex;
   justify-content: space-between;
   overflow: hidden;
   padding: 5%;
}

.recommended-heading-text1 {
   font-size: 1.4rem;
   font-weight: bold;
}

.recommended-heading-text2 {
   font-size: 1.1rem;
   color: #4361ee;
}
.recommended-content-container {
   justify-content: center;
   display: flex;
   gap: 1rem;
   padding: 2% 2% 1% 1%;
}

.recommended-categories-container {
   display: flex;
   flex-direction: column;
   width: 40%;
   gap: 0.5rem;
}
.search-bar-wrapper {
   display: flex;
   justify-content: space-between;
   gap: 0.5rem;
}
.recommended-category-items-wrapper {
   display: flex;
   flex-direction: column;
   overflow-y: scroll;
   scroll-behavior: smooth;
   -ms-overflow-style: none;
   scrollbar-width: none;
   height: 33rem;
}
.recommended-category-items-wrapper::-webkit-scrollbar {
   display: block;
   width: 0.5rem;
   &-track {
      background: #f1f1f1;
   }
   &-thumb {
      background: #888;
      border-radius: 0.5rem;
      cursor: pointer;
   }
}

.recommended-items-container {
   width: 60%;
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
}
.recommended-added-items-heading-text1 {
   font-size: 1.2rem;
   font-weight: bold;
   color: #0f191a;
}

.recommended-added-items-container-text1 {
   display: flex;
   font-size: 1rem;
   font-weight: bold;
   align-items: center;
   text-align: right;
   color: #2d6b4d;
}

.recommended-container-heading {
   display: flex;
   gap: 0.5rem;
   align-items: center;
   padding: 1%;
}

.recommended-added-items-container {
   background-color: #fff;
   position: relative;
   width: 40%;
}

.recommended-items-header-container {
   display: flex;
   justify-content: space-between;
   margin-bottom: 0.75rem;
}
.recommended-add-items-wrapper {
   height: 33rem;
   overflow-y: scroll;
   scroll-behavior: smooth;
   max-width: 100%;
   -ms-overflow-style: none;
   scrollbar-width: none;
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
}
.recommended-add-items-wrapper::-webkit-scrollbar {
   display: block;
   width: 0.5rem;
   &-track {
      background: #f1f1f1;
   }
   &-thumb {
      background: #888;
      border-radius: 0.5rem;
      cursor: pointer;
   }
}
.recommended-items-subcontainer {
   display: flex;
   flex-direction: column;
   max-width: 100%;
   height: 20%;
   gap: 0.5rem;
}

.recommended-added-items-added-items-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 28rem;
   gap: 0.5rem;
   overflow-y: scroll;
   scroll-behavior: smooth;
   -ms-overflow-style: none;
   scrollbar-width: none;
}
.recommended-added-items-added-items-container::-webkit-scrollbar {
   display: none;
}

.recommended-added-items-bottom-container {
   bottom: 0;
   background-color: #fff;
   width: 100%;
   text-align: center;
   padding: 1rem 0;
}
.recommended-categoies-added-items-main-container {
   width: 100%;
   height: 100%;
   display: flex;
   gap: 0.5rem;
}
.recommended-categories-added-items-search-wrapper {
   display: flex;
   flex-direction: column;
   width: 60%;
   gap: 0.5rem;
}
.recommended-items-warning {
   color: #e63946;
   height: fit-content;
   padding-left: 10px;
   margin-top: 20px;
}
