.fc-grey {
  color: #878b8e;
}

#overall {
  overflow-y: scroll;
}
.subscription-card {
  border: 2px solid #0f191a;
  border-radius: 1rem;
  position: relative;
  height: 26rem;
  width: 19rem;
  .card-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-highlight {
    background-color: #6557ff;
    border-radius: 0.3rem;
    color: #fff;
    border: none;
    outline: none;
  }
  // button.card-highlight {

  // }
  .horizontal-rule {
    border: 1px solid #3f3f3f;
    margin: 2rem 0;
    width: 100%;
    background-color: #3f3f3f;
  }
  #recommended {
    color: #0f191a;
    background-color: #f9ca41;
    position: absolute;
    top: -26px;
    width: 70%;
    text-align: center;
    border-radius: 0.5rem 0.5rem 0px 0px;
    font-weight: bold;
  }
}
.subscription-card.dark {
  background-color: #0f191a;
  color: #fff;
}
.subscription-card.light {
  background-color: #fff;
  color: #0f191a;
}
.card-description {
  width: 16rem;
}
