/** @format */

@import "assets/fonts/fonts.scss";

.MenuCardContainer {
   display: flex;
   font-size: 1.3rem;
   align-items: center;
   color: #000;
   background-color: #fff;
   justify-content: space-between;
   padding: 1.3rem;
   cursor: pointer;
   border-radius: 10px;

   &:hover {
      background-color: rgba(0, 0, 0, 0.03);
   }
}

.MenuCardBtnWrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.5rem;
   margin-left: 1rem;
   // width: 30%;
}

.MenuCardSubContainer {
   display: flex;
   align-items: center;
   align-items: flex-end;
}
.MenuCard {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 95%;
}
.MenuCardActive {
   background-color: #e7e8e9;
}
.category-input-container {
   background-color: inherit;
   height: 2.2rem;
}
.invisibleBtn {
   border: none;
   background-color: transparent;
   cursor: pointer;
   outline: none;
}

@media only screen and (max-width: 430px) {
   .MenuCardContainer {
      font-size: 1.2rem;
   }
}
