@import "assets/fonts/fonts.scss";

.deleteModalMainContainer {
  background-color: #fff;
  width: 35%;

  border-radius: 10px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.deleteModal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.deleteModalBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
  // background-color: grey;
}
.deleteModalWarnMessage {
  color: #000;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
}
.customModalContentMessage {
  width: 90%;
  margin-top: 2%;
  text-align: center;
  color: #878c8c;
}
.customModalBtnWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin-top: 5%;
  gap: 0.5rem;
}
.customModalWarnMessage {
  color: #000;
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
}

.customModalMainContainer {
  background-color: #fff;
  width: 40%;

  border-radius: 10px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.cancel-icon-custom-modal {
  position: absolute;
  width: 40%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  height: 25%;
}
