@import 'assets/fonts/fonts.scss';

.combosMainContainer {
  width: 78%;
  padding: 1%;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-x: scroll;
}
.combosHeader {
  font-size: 1.4rem;
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.combosItemWrapper {
  //   background-color: #fff;
  height: 35rem;
  display: flex;
  overflow: scroll;
  align-items: center;
  flex-direction: column;
}
.combosItemWrapper::-webkit-scrollbar {
  display: none;
}
.combo-content-container {
  justify-content: center;
  display: flex;
  gap: 1rem;
  padding: 2% 2% 1% 1%;
}

.combo-categories-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 0.5rem;
}
.search-bar-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
.combo-category-items-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 33rem;
}
.combo-category-items-wrapper::-webkit-scrollbar {
  display: none;
}

.combo-items-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.combo-added-items-heading-text1 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #0f191a;
}

.combo-added-items-container-text1 {
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  align-items: center;
  text-align: right;
  color: #2d6b4d;
}

.combo-container-heading {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1%;
}

.combo-added-items-container {
  background-color: #fff;
  position: relative;
  width: 40%;
}

.combo-items-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}
.combo-add-items-wrapper {
  height: 33rem;
  overflow-y: scroll;
  scroll-behavior: smooth;
  max-width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.combo-add-items-wrapper::-webkit-scrollbar {
  display: none;
}
.combo-items-subcontainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 20%;
  gap: 0.5rem;
}

.combo-added-items-added-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 28rem;
  gap: 0.5rem;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 20%;
}
.combo-added-items-added-items-container::-webkit-scrollbar {
  display: none;
}

.combo-added-items-bottom-container {
  background-color: #fff;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
}
.combo-categoies-added-items-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
}
.combo-categories-added-items-search-wrapper {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 0.5rem;
}
.combo-added-items-container-header {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 5%;
}

.combo-added-items-heading-text1 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #0f191a;
}

.combo-added-items-container-text1 {
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  align-items: center;
  text-align: right;
  color: #2d6b4d;
}

.combo-added-items-bottom-container {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: center;
  padding: 1rem 0;
}

.combo-table {
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.combos-ul {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  list-style: none;
  width: 95%;

  padding-left: 0;
}
.combo-items-container {
  display: flex;
  gap: 0.5rem;
  width: 60%;
}

.combo-table-head-title {
  min-width: fit-content;
  text-align: center;
}
.combo-table-head-image {
  text-align: center;
  min-width: fit-content;
}
.combo-card-image-wrapper {
  width: 3.5rem;
  height: 3rem;
}
.combo-card-main-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.2rem;
  background-color: #fff;
  padding: 1%;
  border-radius: 10px;
}

.combo-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.combo-card-details {
  min-width: 7.5rem;
  text-align: center;
}
.combo-card-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.combo-table-cell {
  background-color: #fff;
  text-align: center;
  color: #5c5e69;
  font-weight: bold;
}
.combo-table-body-row {
  border-radius: 30px;
  background-color: #ffff;
  max-height: 100px;
}
.combo-table-head-cell {
  background: none;
  white-space: nowrap;

  @include font-dmsans;
  color: #000;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
  text-align: center;
}
.combo-table-btn-cell {
  background-color: #fff;
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

//

.combo-row-inputs select {
  border: 1px solid rgba(15, 25, 26, 0.25);
  padding: 0;
  margin: 0;

  height: 1.9rem;
  width: 50px;
  border-radius: 0.3rem;
}
.combo-row-inputs option {
  overflow: hidden;
  margin-top: 10rem;
  text-align: center;
  height: 100%;
}
// .combo-row-inputs option:checked{
//   background-color: #2FBF71;
//   border-radius: 0.3rem;
//   color: #fff;
//   padding: 0.2rem;

// }

.combo-row-inputs div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  width: auto;
}

.comboName-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 20%;
}

.combo-row-inputs input {
  border: 1px solid rgba(15, 25, 26, 0.25);
  height: auto;
  width: 100%;
  margin: 0%;
  color: #000 !important;
  border-radius: 0.3rem;
}

.uploadImage label {
  cursor: pointer;
}
#totalPrice {
  width: 50%;
  color: #000;
}

#comboPrice {
  width: 50%;
  color: #000;
}
#discountPrice {
  width: 50%;
  color: #000;
}
.discountSection {
  display: flex;
  width: 140px;
  margin-top: 5%;
  margin-bottom: 10%;
  height: 2.5rem;
  padding: 0 2% 0 2%;
  align-items: center;
  border-radius: 10px;

  border: 1px solid rgba(15, 25, 26, 0.25);
}
.discounts {
  width: 100%;
}

::file-selector-button {
  display: none;
}

.combo-added-items-tab-container {
  display: flex;
}
.combo-added-items-tab {
  width: 50%;
  text-align: center;
  color: #878c8c;
}

.hrLine {
  width: 100%;
  background-color: #878c8c;
  height: 7%;
  margin-top: 2%;
}

.lineActive {
  background-color: #e63946;
}

.tabActive {
  color: #e63946;
  font-weight: 600;
}

.editForm-MainContainer {
  display: flex;
  height: 60%;
  align-items: center;
  justify-content: center;
}

.EditComboFormContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  height: 50%;
}

.editDiscountSection {
  width: 130px;
}
