/** @format */

@import "assets/fonts/fonts.scss";

.UploadMainContainer {
   margin-bottom: 1%;
}
.BtnContainer {
   height: 6.5rem;
   width: 30.1rem;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-evenly;
   margin-top: 2%;
   border-radius: 10px;
   border-width: 1px;
   border-style: dashed;
   border-color: #4361ee;
}

.UploadBtnLab {
   @include font-dmsans;
   font-weight: 500;
   color: rgba(15, 25, 26, 0.5);
   font-size: 1.1rem;
   width: 100%;
}
.UploadBtn {
   padding: 1%;
}
.ImageWrapper {
   height: 80%;
   width: 20%;
   border-radius: 10px;
}
.PreviewImage {
   width: 100%;
   border-radius: 10px;
   height: 100%;
}
.ImageBtnWrapper {
   width: 40%;
   height: 100%;
   gap: 0.5rem;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
}
@media screen and (max-width: 1400px) {
   .BtnContainer {
      width: 25rem;
   }
}

.uploadBtnMainContainer {
   width: 6rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 1%;
   border-radius: 10px;
   background-color: #eaf0ed;
}

.uploadBtnTitle {
   color: #2d6b4d;
   font-size: 0.8rem;
   white-space: nowrap;
}
