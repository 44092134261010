@import "assets/fonts/fonts.scss";

.SidebarMainContainer {
  width: 20%;
  height: 100%;
  background-color: #ffffff;
  // background-color: lightgrey;
  overflow-y: scroll;
}

.LogoWrapper {
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.SideBarLogo {
  width: 15%;
  margin-bottom: 3%;
}

.SmallSideBarLogo {
  width: 60%;
  margin-bottom: 3%;
}

.SideBarLogoText {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2d6b4d;
  margin-left: 2%;
  @include font-dmsans;
}

.NavItemsWrapper {
  // height: 75vh;
  overflow-y: scroll;
  box-sizing: unset;
  padding: 0 5% 5% 5%;
  margin-top: 1rem;
  // background-color: grey;
}

.NavItemsWrapper::-webkit-scrollbar {
  display: none;
}

.NavItem {
  color: rgba(15, 25, 26, 0.5);
  text-decoration: none;
  font-weight: 600;
  @include font-dmsans;
  font-size: 1.3rem;
  height: 2.7rem;
  border: none;
  width: 100%;
  background: none;
  border-radius: 8px;
  display: flex;
  margin-top: 6%;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.newUser {
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background: none;
  }
}

.active {
  background-color: #e63946;
  color: #ffffff;
  // pointer-events: none;
}
.active:hover {
  background-color: #e63946;
}
.Sidebar-menus h3 {
  font-size: 0.5rem;
}

@media only screen and (max-width: 890px) {
  .NavItemsWrapper {
    display: none;
  }

  .SmallNavItemsWrapper {
    display: block;
    padding: 20%;
  }

  .SmallNavItemsWrapper div {
    margin-top: 40%;
    padding: 7%;
  }

  .SidebarMainContainer {
    width: 20%;
  }

  .LogoWrapper {
    display: none;
  }

  .SmallLogoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50%;
  }
  .SmallNavItemsWrapper div {
    padding: 2%;
    margin-top: 0;
  }
}
