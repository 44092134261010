.planChangedModal {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: brightness(0.6);
    z-index: 1000;   

    .modalContent {
        background-color: #fff;
        border-radius: 0.5rem;
    }
}

.tick {
    font-size: 2rem;
    background-color: #2FBF71;
    border-radius: 50%;
    color: #fff;
    font-weight: bold;
    padding: 0.5rem 1rem;
}
.fc-grey {
    color: #878C8C;
}
.fs-7 {
    font-size: 0.7rem;
}
.returnButton {
    color: #fff;
    background-color: #2D6B4D;
    border: none;
    outline: none;
    border-radius: 0.5rem;
    padding: 0.5rem 3rem;
}

@media screen and (max-width: 768px) {
    .planChangedModal {
        .modalContent {
            width: 70%;
        }
    }
}

@media screen and (min-width: 768px) {
    .planChangedModal {
        .modalContent {
            width: 50%;
        }
    }
}

@media screen and (min-width: 1024px) {
    .planChangedModal {
        .modalContent {
            width: 40%;
        }
    }
}

@media screen and (max-width: 480px) {
    .planChangedModal {
        .modalContent {
            width: 90%;
        }
    }
}
