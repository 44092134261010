/** @format */

@import "assets/fonts/fonts.scss";

.MenuContainer {
   height: 100%;
   width: 100%;
   font-size: 1.5rem;
   color: #e63946;
   @include font-dmsans;
   font-weight: bold;
   display: flex;
   justify-content: space-between;
   gap: 2rem;
   // flex-direction: column;
}

.menu-tab-container {
   background-color: #fff;
   margin-bottom: 1rem;
}

.tab-text {
   font-family: DM Sans;
}

.MenuMainContainer {
   display: flex;
   flex-direction: column;
   gap: 1.5rem;
   margin-top: 2rem;
}

.MenuHeader {
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-wrap: wrap;
   gap: 0.5rem;
   background-color: #fff;
   padding: 1rem;
   border-radius: 10px;
   margin-bottom: 1rem;
}
.MenuHeader h2 {
   font-size: 1.5rem;
   font-weight: bolder;
}
@keyframes widthTransition {
   from {
      width: 100vw;
   }

   to {
      width: 38.5vw;
   }
}
.MenuSubContainer {
   display: flex;
   flex-direction: column;
   height: 100%;
   margin-right: 1rem;
   text-transform: capitalize;
   animation-duration: 0.4s;
}

@keyframes widthTransition2 {
   from {
      width: 0vw;
   }

   to {
      width: 38vw;
   }
}

.MenuItemsSubContainer {
   @extend .MenuSubContainer;
   width: 38vw;
   animation-name: widthTransition2;
   animation-duration: 0.4s;
}
.MenuListContainer {
   height: 63vh;
}

.MenuCategoryWrapper {
   scroll-behavior: smooth;
   width: 100%;
   height: 100%;
   overflow-y: scroll;
   display: flex;
   flex-direction: column;
   gap: 0.3rem;
}

.MenuCategoryWrapper::-webkit-scrollbar {
   display: block;
   cursor: pointer;
   width: 0.5rem;
   &-track {
      background: #f1f1f1;
   }
   &-thumb {
      background: #888;
      border-radius: 0.5rem;
   }
}

.MenuItemWrapper {
   width: 100%;
   height: 100%;
   background-color: #f5f5f5;
   overflow-y: scroll;
   scroll-behavior: smooth;
   display: flex;
   flex-direction: column;
   gap: 0.3rem;
}

.MenuItemWrapper::-webkit-scrollbar {
   display: block;
   width: 0.5rem;
   &-track {
      background: #f1f1f1;
   }
   &-thumb {
      background: #888;
      border-radius: 0.5rem;
      cursor: pointer;
   }
}

.ItemCardMain {
   color: #000;
   font-size: 1rem;
   font-weight: normal;
   background-color: #fff;
}
.NoItemsWrapper {
   justify-content: center;
}
// @media only screen and (max-width: 1100px) {
//   .MenuContainer {
//     flex-direction: column-reverse;
//   }
//   .MenuSubContainer{
//     width: 100% !important;
//     margin-top: 2rem;
//   }
// }
@media screen and (max-width: 500px) {
   .MenuItemsSubContainer {
      width: 100vw;
   }
   .MenuListContainer {
      height: 100%;
   }
   .MenuSubContainer {
      margin-right: 0rem;
   }
}
