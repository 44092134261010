/** @format */

.qr-codes-container {
   // background-color: grey;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   height: 70%;
   overflow: hidden;
   overflow-y: scroll;
   justify-content: center;
   gap: 3rem;
}

.qr-palette {
   background: linear-gradient(90deg, #2e6d4f 25%, #058944 106.97%);
}

.qr-codes {
   position: relative;
}
.palette-container {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: space-around;
   align-items: center;
   margin: 0rem 1.5rem 3rem 1.5rem;
}

.palette {
   height: 40px;
   width: 100px;
   border-radius: 8px;
   cursor: pointer;
}
.palette-sm {
   height: 24px;
   width: 58px;
   border-radius: 8px;
   cursor: pointer;
}

.tables-topbar {
   margin-bottom: 2rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.green {
   color: #058944 !important;
}
.tables-topbar-1 {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.bottom-border {
   border: 1px solid #878c8c;
   border-width: 0px 1px 1px 1px;
   border-radius: 0px 0px 10px 6px;
   padding: 0.2rem 0.6rem;
   display: flex;
   align-items: baseline;
   gap: 0.5rem;
}
.smaller {
   font-size: 0.85rem;
}
.qr-buttons {
   position: absolute;
   height: 100%;
   width: 100%;
   top: 0;
   left: 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: stretch;
   padding: 20%;
   background-color: rgba(0, 0, 0, 0.5);
   transition: opacity 0.3s ease-in-out;
   z-index: 1;
   gap: 1rem;
   opacity: 0;
}
.qr-buttons:hover {
   opacity: 1;
}
.qr-button:hover {
   background-color: #f2f2f2;
}

.table-identifier-input {
   display: inline;
}
.blue {
   color: #4361ee;
}
