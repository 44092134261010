@import 'assets/fonts/fonts.scss';

.SelectInputContainer {
  height: 3rem;
  min-width: 180px;
  border-radius: 0.8rem;
  border: 1px solid rgba(15, 25, 26, 0.25);
  @include font-dmsans;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0% 5% 0% 5%;
}

.OptionsContainer {
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
