@import 'assets/fonts/fonts.scss';

.TopbarMainContainer {
  height: 5%;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  padding-left: 2rem;
}
.IconContainer {
  display: flex;
  width: 20%;
  height: 100%;
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.TopBarIconWrapper {
  background-color: #ffff;
  height: 100%;
  padding: 2.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18%;
  margin-right: 10%;
  border-radius: 5px;
}
.ProfileIconContainer {
  background-color: #000;
  border-radius: 5px;
  width: 95%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}
