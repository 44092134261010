/** @format */
@import "assets/fonts/fonts.scss";

.loyalty-switch-wrapper {
   padding: 3%;
   background-color: #f7f9f8;
   border-radius: 8px;
}
.loyalty-header-title {
   text-transform: none;
   white-space: nowrap;
   font-size: 1.4rem;
   @include font-dmsans;
   font-weight: 600;
}
.custom-switch-label {
   text-transform: none;
   font-size: 1.1rem;
   font-weight: 600;
   > span {
      text-transform: none;
   }

   .green {
      color: #2fbf71;
   }
   .red {
      color: #d90429;
   }
}
.loyalty-main-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   height: 50vh;
}

.loyalty-main-container-header {
   color: #2d6b4d;
   padding: 2% 3% 2% 3%;
   height: 60px;
   font-weight: 600;
   font-size: 1.2rem;
   text-transform: none;
   @include font-dmsans;
}
.loyalty-content-wrapper {
   height: 100%;
}

.loyaltyFormContainer {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-evenly;
   gap: 5%;
   padding: 2% 4% 2% 4%;
   height: 100%;
}
.loyaltyFormGroup {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
}
.loyaltyInput {
   width: 25%;
}
.loyaltyLabel {
   font-weight: 600;
   font-size: 1rem;
   @include font-dmsans;
   color: #000;
   width: 70%;
   text-transform: none;
}
.loyalty-card-wrapper {
   display: flex;
   width: 100%;
   height: 100%;
   flex-direction: row;
   padding: 2%;
   gap: 1rem;
   align-items: center;
   justify-content: center;
}
.LoyaltyButtonWrapper {
   padding: 2%;
}
