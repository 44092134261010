.recommendedMainBox {
    background-color: #fff;

    .detailsBox {
        border: 2px solid #0F191A;
        background-color: #FBC52A;
        border-radius: 0.5rem;
    }
}
.planName {
    color: #4361EE;
    letter-spacing: 0.1rem;
    font-weight: bold;
}

.recommended {
    font-size: 0.7rem;
    background-color: #fff;
    border-radius: 0.2rem;
    padding: 0.2rem 0.5rem;
}

.upgradeButton {
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
}