.UnderConstructionGIF {
    height: 57%;
    width: 44%;
  }
  
.GIFContainer {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
}