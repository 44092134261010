@import "assets/fonts/fonts.scss";

.NormallabelText {
  @include font-dmsans;
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 2rem;
}

.NormalInputContainer {
  height: 3rem;
  width: 100%;
  border-radius: 0.8rem;
  margin-top: 0.2rem;
  border: 1px solid rgba(15, 25, 26, 0.25);
  @include font-dmsans;
  margin-bottom: 0.2rem;
  padding-left: 1rem;
  font-size: 1.2rem;
}

.inputMainContainer {
  width: 50%;
  display: flex;
}
@media only screen and (max-width: 890px) {
  .inputMainContainer {
    width: 100%;
  }
}
