.points-outer {
  width: 100%;
  padding: 1rem;
}

.points-head {
  font-size: 1.5rem;
  font-weight: bold;
}

.points-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}
.credits-available {
  font-size: 1.2rem;
  padding: 1%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  :nth-child(2) {
    color: #4361ee;
  }
}
