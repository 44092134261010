
.NewCategoryContainer {
  background-color: #fff;
  padding: 2%;
  height: auto;
  display: flex;
  flex-wrap: wrap;

  padding-left: 2%;
  padding-right: 2%;
  align-items: center;
  border-radius: 10px;
  justify-content: space-between;
}

.MenuBtnWrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.nav-log-out-container {
  width: 100%;
  display: flex;
  padding: 0 8% 0 8%;
  align-items: center;
  justify-content: center;margin-top: 1rem;
}
