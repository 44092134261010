@import 'assets/fonts/fonts.scss';

.analyticsCardMainContainer {
  background-color: #fff;
  // width: 27%;
  padding:2% ;
  border-radius: 0.5rem;
  min-height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 0.5rem 0.1rem #e0e0e0;
}
.analyticsCardValue {
  @include font-dmsans;
  font-size: 1.8rem;
  color: #000;
  font-weight: 600;
}
.analyticsCardHeader {
  @include font-dmsans;
  font-weight: 600;
  font-size: 1.1rem;
  text-align: center;
  color: #878c8c;
  display: flex;
}
