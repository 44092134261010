/** @format */

@import "assets/fonts/fonts.scss";

.AddItemModalContainer {
  float: right;
  width: 38%;
  background-color: #ffff;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  // overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.AddItemModalContainer::-webkit-scrollbar {
  display: none;
}

.AddItemModalContainer:focus-visible {
  outline: none !important;
}

.add-on-form-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddItemModalHeader {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e63946;
  font-weight: bold;
  @include font-dmsans;
  font-size: 1.5rem;
  position: sticky;
  background-color: #ffff;
  top: 0;
}

.AddItemModalForm {
  padding: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.formGroup {
  display: flex;
  align-items: center;
  max-width: 95%;
  gap: 0.5rem;
}
.AddItemBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 5%;
  margin-top: auto;
}
.itemTypeWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.AddItemModalTabs {
  margin: 0.5rem 1rem;
  padding: 0px 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
}
.AddItemModalCustomizationForm {
  padding: 1rem;
  margin: 1rem;
  gap: 2rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  width: 93%;
  height: 90%;
}
.CustomizationCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.add-on-search-container {
  padding: -0.3rem 1rem;
  margin: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.addCustomization {
  color: #4361ee;
  text-align: center;
  font-size: 1.2rem;
  width: 100%;
  cursor: pointer;
  margin-top: 1rem;
}
.optionText {
  font-size: 0.9rem;
  color: #2d6b4d;
}
@media only screen and (max-width: 1100px) {
  .AddItemModalContainer {
    width: 50%;
  }
}
@media only screen and (max-width: 700px) {
  .AddItemModalContainer {
    width: 70%;
  }
}
@media only screen and (max-width: 450px) {
  .AddItemModalContainer {
    width: 80%;
  }
}
