@import 'assets/fonts/fonts.scss';
.addComboFormContainer {
  display: flex;
  align-items: center;
  background-color: #ffff;
  border-radius: 10px;
  padding: 1%;
  gap: 3%;
}

#discount {
  border: none;
  outline: none;
}
