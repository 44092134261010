@import 'assets/fonts/fonts.scss';

.QRCardContainer {
  width: 10rem;
  background-color: #fff;
  border: 1px solid rgba(15, 25, 26, 0.5);
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 1%;
  border-radius: 10px;
  z-index: 1;
  overflow: hidden;
}

.QRModel {
  background-color: rgba(15, 25, 26, 0.5);
  width: 10rem;
  height: 12rem;
  border-radius: 10px;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 111;
}
.QRModelBtn {
  width: 100%;
  // height: 9.9rem;
  border-radius: 10px;
  gap: 0.5rem;
  display: grid;
  align-items: center;
  justify-content: center;
  // position: fixed;
}
.QRModelCopyBtn {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  width: 25%;
  border-radius: 10px;
  gap: 0.5rem;
  margin-bottom: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.QRimage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.QRText {
  font-size: 1.1rem;
  padding: 1%;
  color: #2d6b4d;
  white-space: nowrap;
  @include font-dmsans;
  font-weight: bold;
}
