@import 'assets/fonts/fonts.scss';

.button1Container {
  background-color: #2d6b4d;
  width: 30rem;
  height: 4rem;
  border-radius: 0.8rem;
  @include font-dmsans;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 60px;
  font-size: 1.4rem;
  cursor: pointer;
}
