.loader-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container2 {
  padding: 20px;
  width: 15%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  flex-direction: column;
}

.loader-please-wait-text {
  margin-top: 1.5rem;
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 1.5rem;
}
