@import 'assets/fonts/fonts.scss';

.onBoardingWrapper {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.country-pincode-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.headerText {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: -3rem;
}

.space {
    margin-top: 0.5rem;
}
 
.vector1{
    position: fixed;
    top: 0;
    right: 0;
    height: 200;
    width: 200;
}

.vector2 {
    position: fixed;
    left: 0;
    bottom: 0;
}