/** @format */

@import "assets/fonts/fonts.scss";

.MenuItemCardContainer {
   padding: 2%;
   display: flex;
   background-color: #fff;
   border-radius: 10px;
   color: #000;
   gap: 0.5rem;
}
.MenuItemImageWrapper {
   width: 4.5rem;
   height: 4rem;
   border-radius: 8px;
   overflow: hidden;
}
.MenuItemImage {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.MenuItemNameLogo {
   background-color: rgba(230, 57, 70, 0.1);
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   color: rgba(230, 57, 70, 1);
}
.MenuItemDetails {
   display: flex;
   flex-direction: column;
}
.ItemName {
   font-size: 1.1rem;
}
.ItemDescription {
   font-size: 0.8rem;
   font-weight: 400;
   color: rgba(15, 25, 26, 0.5);
}
.ItemPrice {
   font-size: 1rem;
   margin-right: 0.3rem;
}
.MenuItemBtns {
   width: auto;
   display: flex;
   align-items: center;
   flex-direction: column;
   gap: 0.5rem;
   margin-top: 0.6rem;
}
.SliceBtn {
   color: #2d6b4d;
   text-transform: none;
}
.itemCardBtn {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.5rem;
}
.stockBtn {
   width: 100%;
}
.MenuItemContentWrapper {
   display: flex;
   width: 100%;
   justify-content: space-between;
}

.ItemPriceContainer {
   display: flex;
   align-items: center;
}

.ItemRemainingItems {
   font-size: 0.8rem;
   white-space: nowrap;
   color: green;
   text-transform: none;
}

@media only screen and (max-width: 560px) {
   .ItemName {
      font-size: 1rem;
   }
   .MenuItemContentWrapper {
      flex-direction: column;
      align-items: flex-end;
   }
   .MenuItemCardContainer {
      margin-left: 0.5rem;
   }
}
@media only screen and (max-width: 560px) {
   .MenuItemContentWrapper {
      align-items: center;
   }
}
