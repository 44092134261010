p{
    margin: 0;
    font-size: 11px;
}


.print-order-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    .print-order-container{
        overflow-y: scroll;
        background-color:#ffffff;
       
        z-index: 9999;
        position: relative;
        border-radius: 10px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        .print-order-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
                        
            .print-order-title{
                font-size: 20px;
                font-weight: bold;
            }
            .print-order-close{
                font-size: 20px;
                cursor: pointer;
            }
        }
        .print-order-body{
            margin-top: 20px;
            .print-order-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                .print-order-item-name{
                    font-size: 16px;
                    font-weight: 600;
                }
                .print-order-item-price{
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
        .print-order-footer{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-direction: column;
            align-self: flex-end;
            // background-color: red;
            .print-order-total{
                font-size: 20px;
                font-weight: 600;
            }
            .print-order-print{
                font-size: 20px;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
}

.button-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;

}

.print-btn{
    border: 1px solid #e0e0e0;
    background-color: #e0e0e0;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    color: #000000;
    &:hover{
        background-color:#2D6B4D;
        color: #ffffff;
    }
}

.cancel-btn{
    border: 1px solid #e0e0e0;
    border-radius: 5px;
padding: 0.2rem 0.5rem;
    background-color:#e0e0e0;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    color: #000000;
    &:hover{
        background-color: #4361EE;
        color: #ffffff;
    }
}

.print-item-name{
    font-size: 12px;
   font-weight: 600;
//    min-width: 50px;;
}
.print-item-count {
    font-size: 10px;
    // min-width: 20px;
    // width: 120px;
    // background-color: red;
    
}

.total-details-container{
    margin-top: 7px;
}