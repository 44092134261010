/** @format */

.recommended-item-added-container {
   background: #ffffff;
   border: 1px solid rgba(15, 25, 26, 0.25);
   border-radius: 12px;
   width: 90%;
   display: flex;
}
.recommended-item-added-image-wrapper {
   width: 5.2rem;
   height: 4.3rem;
   border-radius: 5px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: auto;
}
.recommended-item-added-image-logo {
   background-color: rgba(230, 57, 70, 0.1);
   height: 100%;
   width: 100%;
   border-radius: 5px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: bold;
   font-size: 1.2rem;
   color: rgba(230, 57, 70, 1);
}
.recommended-item-added-image {
   border-radius: 5px;
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.recommended-item-added-second-column {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   margin-left: 12px;
}

.recommended-item-added-second-column-item-name {
   font-weight: bold;
   font-size: 0.9rem;
   color: #0f191a;
}

.recommended-item-added-second-column-item-price {
   font-weight: 500;
   font-size: 0.9rem;
   color: #0f191a;
}

.recommended-item-added-second-column-btn {
   background: rgba(67, 97, 238, 0.1);
   border: 1px solid #4361ee;
   border-radius: 4px;
   color: #4361ee;
   font-weight: bold;
   font-size: 0.7rem;
   width: fit-content;
}

.recommended-item-added-third-column-container {
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-right: 3%;
}

.recommended-item-added-third-column-btn {
   background: rgba(217, 4, 41, 0.1);
   border: 1px solid #d90429;
   border-radius: 5px;
   font-weight: bold;
   font-size: 0.8rem;
   color: #d90429;
   display: flex;
   align-items: center;
   justify-content: center;
}

.recommended-item-added-right-section {
   display: flex;
   justify-content: space-between;
   width: 100%;
}

.recommended-added-items-bottom-container {
   background: #ffffff;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 10%;
}
