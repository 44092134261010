/** @format */

.mainContainer {
     min-height: 100%;
     padding: 0.5rem;
}
.tab-text {
     color: rgba(15, 25, 26, 0.5) !important;
     text-decoration: none !important;
     font-weight: 600 !important;
     font-family: "DM Sans", sans-serif !important;
     font-size: 1.3rem !important;
     height: 2.7rem !important;
     cursor: pointer !important;
     text-transform: none !important;
}
.tab-text-selected {
     color: #2d6b4d !important;
}

.special-header {
     font-weight: bold;
     font-size: 1.5rem;
     margin-left: 1rem;
}

.special-count {
     font-size: 1.3rem;
     color: #2d6b4d;
     margin-left: 0.8rem;
}
.specials-list {
     display: flex;
     flex-direction: column;
     gap: 0.5rem;
     margin-top: 1rem;
}
@import "assets/fonts/fonts.scss";

.recommended-added-items-container-header {
     display: flex;
     justify-content: space-between;
     overflow: hidden;
     padding: 5%;
}

.recommended-heading-text1 {
     font-size: 1.4rem;
     font-weight: bold;
}

.recommended-heading-text2 {
     font-size: 1.1rem;
     color: #4361ee;
}
.recommended-content-container {
     justify-content: center;
     display: flex;
     gap: 1rem;
     padding: 2% 2% 1% 1%;
}

.recommended-categories-container {
     display: flex;
     flex-direction: column;
     width: 40%;
     gap: 0.5rem;
}
.search-bar-wrapper {
     display: flex;
     justify-content: space-between;
     gap: 0.5rem;
}
.recommended-category-items-wrapper {
     display: flex;
     flex-direction: column;
     overflow-y: scroll;
     scroll-behavior: smooth;
     -ms-overflow-style: none;
     scrollbar-width: none;
     height: 33rem;
}
.recommended-category-items-wrapper::-webkit-scrollbar {
     display: block;
     width: 0.5rem;
     &-track {
          background: #f1f1f1;
     }
     &-thumb {
          background: #888;
          border-radius: 0.5rem;
          cursor: pointer;
     }
}

.recommended-items-container {
     width: 60%;
     display: flex;
     flex-direction: column;
     gap: 0.5rem;
}
.recommended-added-items-heading-text1 {
     font-size: 1.2rem;
     font-weight: bold;
     color: #0f191a;
}

.recommended-added-items-container-text1 {
     display: flex;
     font-size: 1rem;
     font-weight: bold;
     align-items: center;
     text-align: right;
     color: #2d6b4d;
}

.recommended-container-heading {
     display: flex;
     gap: 0.5rem;
     align-items: center;
     padding: 1%;
}

.recommended-added-items-container {
     background-color: #fff;
     position: relative;
     width: 40%;
}

.recommended-items-header-container {
     display: flex;
     justify-content: space-between;
     margin-bottom: 0.75rem;
}
.recommended-add-items-wrapper {
     height: 33rem;
     overflow-y: scroll;
     scroll-behavior: smooth;
     max-width: 100%;
     -ms-overflow-style: none;
     scrollbar-width: none;
     display: flex;
     flex-direction: column;
     gap: 0.5rem;
}
.recommended-add-items-wrapper::-webkit-scrollbar {
     display: block;
     width: 0.5rem;
     &-track {
          background: #f1f1f1;
     }
     &-thumb {
          background: #888;
          border-radius: 0.5rem;
          cursor: pointer;
     }
}
.recommended-items-subcontainer {
     display: flex;
     flex-direction: column;
     max-width: 100%;
     height: 20%;
     gap: 0.5rem;
}

.recommended-added-items-added-items-container {
     display: flex;
     flex-direction: column;
     align-items: center;
     height: 28rem;
     gap: 0.5rem;
     overflow-y: scroll;
     scroll-behavior: smooth;
     -ms-overflow-style: none;
     scrollbar-width: none;
}
.recommended-added-items-added-items-container::-webkit-scrollbar {
     display: none;
}

.recommended-added-items-bottom-container {
     bottom: 0;
     background-color: #fff;
     width: 100%;
     text-align: center;
     padding: 1rem 0;
}
.recommended-categoies-added-items-main-container {
     width: 100%;
     height: 100%;
     display: flex;
     gap: 0.5rem;
}
.recommended-categories-added-items-search-wrapper {
     display: flex;
     flex-direction: column;
     width: 60%;
     gap: 0.5rem;
}
.recommended-items-warning {
     color: #e63946;
     height: fit-content;
     padding-left: 10px;
     margin-top: 20px;
}
.specials-edit-hourly-dets {
     display: flex;
     gap: 1rem;
     padding: 1rem;
     margin: 1rem 0.5rem 0 0.5rem;
     background-color: white;
     border-radius: 0.5rem;
     color: #0f191a80;
}
.specials-edit-hourly-dets-warning {
     color: #e63946;
     height: fit-content;
     padding-left: 10px;
     font-size: 0.8rem;
}

.type-of-day {
     border: 1px solid #0f191a40;
     border-radius: 0.5rem;
     padding: 0.5rem 1rem;
     font-size: 1rem;
     color: #0f191a;
     min-width: 8rem;
     cursor: pointer;
     margin-right: 0.5rem;
     text-align: center;
}

.type-of-day-selected {
     color: #2d6b4d;
     border-color: #2d6b4d;
     background-color: #2d6b4d40;
}
