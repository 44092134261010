span {
  display: inline-block;
}
.points-card-outer {
  text-transform: none;
  width: 20rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  height: 12rem;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1%;
  border: 1px solid #c3c5c6;
}
.points-card-outer-custom {
  text-transform: none;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1%;
  border: 1px solid #c3c5c6;
  width: 28rem;
  height: 16rem;
}
.points-word {
  font-size: 0.9rem;
  margin-left: 2px;
  text-transform: none;
  color: #878c8c;
}
.points {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1rem;
}
.amount-word {
  font-size: 0.9rem;
}
.amount {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4361ee;
  background-color: #eceffd;
  padding: 0.5rem 0.7rem;
  border-radius: 0.4rem;
  margin-right: 0.5rem;
  min-width: 4rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  display: flex;
  flex-direction: column;

  text-align: center;
}
.buy-now {
  color: #f5f6f6;
  font-size: 1.3rem;
}
.buy-now-btn {
  background-color: #4361ee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.points-input {
  font-size: 1.3rem;
  font-weight: 400;
  padding: 3% 3% 3% 8%;
  border-radius: 10px;
  border: 1px solid #c3c5c6;
  outline: none;
  display: inline-block;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: "#000";

  &::placeholder {
    margin-top: 0;
    color: "#000";
    font-size: 1.2rem;
    font-weight: 400;
  }
}

.points-card-inner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.points-card-auto-renewal-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  border: 1px solid #f9ca41;
  background-color: #fefaec;
  padding: 0 5% 0 5%;
  border-style: dashed;
  border-radius: 8px;
}

.point-card-switch-wrapper {
  margin-top: 4%;
  margin-bottom: 1%;
}
