.form-check-input:checked {
  background-color: #2fbf71;
  border-color: #2fbf71;
}

.form-check-input:focus {
  box-shadow: none;
}

#close {
  aspect-ratio: 1 / 1;
  width: 3rem;
  border: none;
  background-color: #fff;
}

#side-wrapper {
  transition: transform 0.5s ease-in, 0.5s 0.5s ease-out; //transition delay
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

#side-wrapper.open::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  z-index: -33333;
  background-color: rgba(0, 0, 0, 0.286);
  transition: 0.5s;
  transition-delay: 1s;
}

#side-wrapper.close::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  z-index: -33333;
  background-color: transparent;
  transition: 0s;
}

.btn-icon {
  width: 24px;
  height: 24px;
}

.btn-text {
  color: #4361ee;
  margin-left: 0.8rem;
}

.table-body {
  // background-color: grey;
  overflow-y: scroll;
  width: 100%;
  display: inline-block;
  max-height: 50vh;

  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    margin-right: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 0.2rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  // display: block;
}

#side-wrapper.open {
  transform: translateX(0%);
  // backdrop-filter: brightness(0.5);
}

#side-wrapper.close {
  transform: translateX(100%);
  // backdrop-filter: brightness(1);
  // backdrop-filter:none;
  transition: 2s;
}

#order-side {
  height: 100vh;
  background-color: #fff;
  border: none;
  width: 45vw;
  overflow: scroll;

  > :last-child {
    button {
      border: none;
      outline: none;
      cursor: pointer;
    }

    .b-btn {
      background-color: #4361ee;
      color: #fff;
      border: 1px solid #4361ee;
      padding: 0.5rem;
    }

    .r-btn-h {
      background-color: #fbe6ea;
      color: #e63946;
      border: 1px solid #e63946;
      padding: 0.5rem;
    }

    .g-btn {
      background-color: #2fbf71;
      color: #fff;
      border: 1px solid #2fbf71;
      padding: 0.5rem;
    }

    .g-btn-h {
      background-color: #fff;
      color: #2fbf71;
      border: 1px solid #2fbf71;
      padding: 0.5rem;
    }
  }
}

#highlighted {
  >div {
    border: 1px solid #000;
  }

  > :nth-child(1) {
    background-color: #eaf0ed;
  }
}

.slight-faded {
  color: #878c8c;
  font-size: 0.85rem;
  font-weight: bold;
}

#padded {
  padding: 0rem 2rem;
  overflow-y: scroll;
}

#row-det {
  padding-top: 2rem;
  padding-bottom: 0.75rem;
  row-gap: 0.5rem;

  >div {
    padding: 0px 0.1rem;

    > :nth-child(1) {
      min-width: 27%;
      display: inline-block;
    }

    > :nth-child(2) {
      margin-right: 1rem;
      display: inline-block;
    }

    > :nth-child(3) {
      display: inline-block;
      color: #2d6b4d;
    }
  }
}

#dine-in-table {
  width: 100%;

  tr {
    border: none;
  }

  >thead {
    >tr {
      width: fit-content;
      border: 1px solid #c3c5c6;
      border-width: 1px 0px;

      > :nth-child(1) {
        width: 60%;
      }
    }
  }

  thead tr th,
  tbody tr td {
    padding: 0.5rem 0.5rem;

  }
}

#items-table {
  width: 40vw;
  height: 60vh;
  position: relative;

  tr {
    border: none;

  }

  >thead {
    width: 40vw;

    >tr {
      width: 100%;
      border: 1px solid #c3c5c6;
      border-width: 1px 0px;

      > :nth-child(1) {
        width: 80%;
      }

      > :nth-child(2) {
        width: 35%;
      }

      > :nth-child(3) {
        width: 10%;
      }
    }
  }

  >tbody {
    width: 40vw;

    >tr {
      > :nth-child(1) {
        width: 80%;
        height: 2.5rem;
      }

      > :nth-child(2) {
        width: 35%;
        height: 2.5rem;
      }

      > :nth-child(3) {
        width: 10%;
      }
    }
  }
}

.newly-added {
  color: #2fbf71;
  font-weight: 500;
}

@keyframes elevate {
  100% {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

#bottom-table-body {
 
  width: 100%;
}

.confirm-btn {
  padding: 1%;
  display: flex;
  justify-content: center;
  align-self: center;
  bottom: 2rem;
  background: none;
}

.bottom-table-body-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1%;
}

.bottom-table-total-amount {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
}

.bottom-table-total-items {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4361ee;
}

.overlay {
  background-color: transparent;
  width: 49%;
  height: 100%;
}

.cancelled {
  background-color: #fbe6ea;
  color: #e63946;
  border: 1px solid #e63946;
  padding: 0.5rem 2.3rem;
}

.cancelled-btn {
  background-color: #d90429;
  color: #ffff;
  border: 1px solid #d90429;
  padding: 0.5rem;
  border-radius: 8px;
}

.total_items_text {
  color: #2d6b4d;
  font-weight: bold;
  font-size: 1.2rem;
}

.total_items_value {
  color: #4361ee;
}

.table-outside-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.no-of-guest-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0;

  input {
    width: 50px;
    text-align: start;
    padding: 0;
    height: fit-content;
    height: 100%;
    border-radius: 0;
    color: #2d6b4d;
  }

  .guest-btn {
    color: #4361ee;
    font-size: 14px;
    text-transform: none;
  }
}