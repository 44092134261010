@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.table-content {
  border-top: 1px solid black;
  border-collapse: collapse;

    font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

.columnText {
    font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #000000;
}

td.description,
th.description {
  width: 75px;
  max-width: 75px;

    font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

td.quantity,
th.qty {
  width: 40px;
  max-width: 40px;
  word-break: break-all;
   font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

td.price,
th.price {
  width: 40px;
  max-width: 40px;
  word-break: break-all;
    font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

.centered {
  text-align: center;
  align-content: center;
   font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin: 0;
  margin-top: 10px;
}

.left {
  text-align: left;
  align-content: left;
    font-size: 12px;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.ticket {
  width: 155px;
  max-width: 155px;
    font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

img {
  max-width: inherit;
  width: inherit;
}

@media print {
  .hidden-print,
  .hidden-print * {
    display: none !important;
    background-color: #2d6b4d;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    transition: all 0.3s ease-in-out;
  }
}
