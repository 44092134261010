@import 'assets/fonts/fonts.scss';

.ToggleContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-around;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
   
}
.Width {
  width: 10rem;
  gap: 0.5rem;
}
.ToggleLabel {
  font-size: 1rem;
  @include font-dmsans;
  font-weight: 400;
  text-transform: none;
  word-wrap: none;
  width: 100%;
  white-space: nowrap;
}
.ToggleStockContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.InStockLabel {
  color: #2d6b4d;
  @include font-dmsans;
  font-weight: bold;
  white-space: nowrap;
  font-size: 1rem;
}
.OutStockLabel {
  color: #d90429;
  font-size: 1rem;

  font-weight: bold;
  @include font-dmsans;
  white-space: nowrap;
}
