.LayoutMainContainer {
  display: flex;
  height: 100vh;
  overflow: unset;
  overflow: hidden;
  background-color: #f5f5f9;
}

.LayoutContainer {
  width: 80%;
  height: auto;
  overflow: scroll;
}
.sidebar{
  display: block;
}


.BodyContainer {
  padding: 1rem;
  height: 100vh;
  overflow: scroll;
}

@media only screen and (max-width: 890px) {
  
 
}