@import 'assets/fonts/fonts.scss';

.SearchBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2%;
  border-radius: 5px;
  background-color: #ffff;
}
.SearchInput {
  border: none;
  outline-style: none;
  width: 100%;
  margin-left: 1%;
  display: flex;
  @include font-dmsans;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  &::placeholder {
    font-weight: 400;
  }
}
