/** @format */

.hourly-card {
     background-color: white;
     display: flex;
     align-items: stretch;
     justify-content: space-between;
     padding: 1rem;
     border-radius: 0.5rem;
}
.small-grey {
     font-size: 0.8rem;
     color: #0f191a80;
}
.blue {
     color: #4361ee;
     background-color: white;
}
.buttons-outer {
     display: flex;
     gap: 0.5rem;
     width: max-content;
}
