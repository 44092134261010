/** @format */
.waiterHeading {
   display: flex;
   justify-content: space-between;
   padding: 2rem 0;
}
.waiterCardListContainer {
   display: flex;
   flex-direction: column;
   gap: 1rem;
}

// WAITERCARD STYLES
.waiterCardContainer {
   display: flex;
   justify-content: space-between;
   align-items: center;
   background-color: #fff;
   padding: 1rem;
   border-radius: 0.7rem;
   gap: 1.5rem;
}

.waiterButtonsContainer {
   display: flex;
   align-self: end;
   gap: 1rem;
}
.waiterDetailsLabel,
.waiterDetails {
   font-size: 1rem;
   margin-bottom: 1rem;
}
.waiterDetailsLabel {
   color: #868b8c;
}
.waiterUserDetails {
   width: 25%;
}
.waiterInputField {
   outline: none;
   border: 1px solid #c1c1c1;
   padding: 0.5rem 1rem;
   width: 100%;
   font-size: 1rem;
   font-weight: 400;
   border-radius: 0.6rem;
}
.waiterInputField:focus {
   border: 1px solid black;
}
