@import 'assets/fonts/fonts.scss';

.button2Container {
  background-color: #fff;
  width: 30rem;

  height: 3rem;
  border-radius: 0.8rem;
  @include font-dmsans;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 60px;
  font-size: 1.3rem;
  border: 1px solid #0f191a;
  cursor: pointer;
  white-space: nowrap;
}

.Btn2sm {
  width: 7rem;
  height: 2.4rem;
  padding: 1%;
  gap: 0.2rem;
  display: flex;
  border-radius: 5px;
  font-size: 1rem;
}

.Btn2md {
  width: 12rem;
  height: 2.5rem;
  padding: 1%;
  gap: 0.5rem;
  display: flex;
  border-radius: 5px;
  font-size: 1rem;
}

.Btn2xs {
  max-width: 3rem;
  height: 1.5rem;
  gap: 0.2rem;
  display: flex;
  border-radius: 2px;
  font-size: 0.8rem;
}

button:focus-visible {
  outline:none!important;;
}

