/** @format */

@import "assets/fonts/fonts.scss";

.labelText {
   @include font-dmsans;
   font-weight: 500;
   color: rgba(15, 25, 26, 0.5);
   font-size: 0.9rem;
   margin-top: 1%;
   width: 100%;
   white-space: nowrap;
   flex-wrap: nowrap;
   text-transform: none;
}
.inputMainContainer {
   display: flex;
   flex-direction: row-reverse;
   align-items: center;
   justify-content: flex-end;
   border-radius: 8px;
}
.inputContainer {
   width: 100%;
   height: 3rem;
   border-radius: 8px;
   margin-top: 0.5rem;
   border: 1px solid rgba(15, 25, 26, 0.25);
   @include font-dmsans;
   font-size: 1rem;
   display: flex;
   align-items: center;
   justify-content: center;
}
.FileInput {
   width: 29rem;
   height: 6rem;
   padding-left: 1rem;
   border-style: dashed;
   border-radius: 5px;
   border-width: 1px;
   border-color: #4361ee;
}
.TextArea {
   outline: none;
   resize: vertical;
   height: auto;
   border-radius: 0.8rem;
   margin-top: 0.5rem;
   padding: 2%;
   border: 1px solid rgba(15, 25, 26, 0.25);
   @include font-dmsans;
   padding-left: 1rem;
   font-size: 0.9rem;
   line-height: 1.2rem;
}

::placeholder {
   font-size: 1rem;
   font-weight: 500;
   @include font-dmsans;
   color: rgba(15, 25, 26, 0.25);
}
.Holder {
   font-size: 1rem;
   font-weight: 500;
   @include font-dmsans;
   height: 100%;
}

.inputCheckBox {
   margin-right: 2%;
   width: 50px;
}
.CheckBoxLabel {
   display: flex;
   margin-bottom: 0.5%;
}
.ErrorMessage {
   color: red;
   padding-left: 1%;
   // height: 1rem;
   font-size: 0.8rem;
   margin-top: 1%;
}
.InputErrorWrapper {
   display: flex;
   justify-content: center;
   flex-direction: column;
}
.optionaltag {
   color: #4361ee;
}
.requiredtag {
   color: red;
}
option {
   @include font-dmsans;
   font-weight: 500;
   color: rgba(15, 25, 26, 0.5);
   font-size: 1rem;
   margin-top: 1%;
   width: 100%;
   padding: 1%;
}

input {
   width: 96%;
   height: 80%;
   border-radius: 8px;
   border: none;
   outline: none;
   @include font-dmsans;
   font-size: 1.2rem;
   padding-left: 1rem;
   background-color: #fff;
   background: none;
}
.RupeesIcon {
   margin-left: 10%;
}
select {
   width: 95%;
   height: 100%;
   border-radius: 8px;
   border: none;
   outline: none;
   @include font-dmsans;
   font-size: 1rem;
}

input[type="number"] {
   -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
   margin: 0;
   display: none;
}

@media screen and (min-width: 1400px) {
   .inputContainer {
      // width: 24rem;
   }

   .TextArea {
      width: 24rem;
   }
}
@media screen and (max-width: 1000px) {
   .inputContainer {
      // width: 20rem;
   }
   .labelText {
      text-align: center;
   }
   .optionaltag,
   .requiredtag {
      display: none;
   }
}
