/** @format */

.standalone-container {
   // background-color: grey;
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: flex-end;
}

.standalone-selection-images {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   // width: 100%;
   // height: 100%;
}

.standalone-image {
   object-fit: contain;
   align-self: center;
}

.standalone-utilities-wrapper {
   align-self: flex-start;
}
.standalone-utilities {
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: center;
   align-self: stretch;
   height: 90%;
   flex-grow: 1;
   > * {
      margin-bottom: 1rem;
   }
}

.two-buttons {
   display: flex;
   flex-direction: row;
   justify-content: end;
   gap: 1rem;
   align-items: center;
   margin-left: 2.5rem;
}

.orientation-button {
   outline: none;
   border: 1px solid black;
   padding: 0.8rem 1.5rem;
}

.orientation-wrapper {
   margin-bottom: 2rem;
   margin-left: 2.5rem;
}
.utilities-head {
   font-weight: bold;
   text-align: left;
   font-size: 1.3rem;
   margin-left: 2.5rem;
   margin-bottom: 1rem;
}

.hr {
   height: 1px;
   margin-left: 2.5rem;
   background-color: #c3c5c6;
}
