@import 'assets/fonts/fonts.scss';

.filter-btn-container {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1% 3% 1% 3%;
  border-radius: 5px;
  border: 1px solid #000;
}
