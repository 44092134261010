/** @format */

@import "assets/fonts/fonts.scss";

.analyticsMainContainer {
   // position: fixed;
   // top: 10%;
   // width: 77%;
   width: 100%;
   padding: 0 1% 0 1%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 1rem;
   background-color: white;
}
.analyticsHeader {
   font-size: 1.4rem;
   font-weight: 600;
   color: #000;
}
.analyticsHeaderWrapper {
   display: flex;
   justify-content: start;
   align-items: center;
   width: 100%;
   gap: 1rem;
}
.analyticsTotalValues {
   display: flex;
   gap: 0.5rem;
   flex-wrap: wrap;
   justify-content: start;
   align-items: stretch;
   margin-top: 1rem;
}
.analyticsMostOrdered {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 0.5rem;
}
@media only screen and (max-width: 890px) {
   .analyticsTotalValues {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
   }
}
.analyticsTabs {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: stretch;
   gap: 0;
}
.bar-chart-wrapper {
   width: 49%;
   background-color: #fff;
   padding: 0.7rem;
   border-radius: 0.7rem;
   box-shadow: 0 0 0.5rem 0.1rem #e0e0e0;
}
.tab-text {
   color: rgba(15, 25, 26, 0.5) !important;
   text-decoration: none !important;
   font-weight: 600 !important;
   @include font-dmsans;
   font-family: "DM Sans", sans-serif !important;
   font-size: 1.3rem !important;
   height: 2.7rem !important;
   cursor: pointer !important;
   text-transform: none !important;
}
.tab-text-selected {
   color: #2d6b4d !important;
}
.analytics-table {
   width: 100%;
   background-color: #fff;
}
.analytics-table-title {
   font-size: 1rem;
   font-weight: 600;
   color: #000;
   margin-bottom: 1rem;
   word-break: break-all;
}
.table-wrapper {
   width: 49%;
   background-color: #fff;
   padding: 0.7rem;
   border-radius: 0.7rem;
   box-shadow: 0 0 0.5rem 0.1rem #e0e0e0;
}
.analytics-table-content {
}
.analytics-table-table {
   width: 100%;
   border-collapse: collapse;
   border-spacing: 0;
   border: 1px solid #e0e0e0;
   border-radius: 0.7rem;
   padding: 0.3rem;

   tr {
      border: 1px solid #e0e0e0;
      padding: 0.3rem;
   }
   th,
   td {
      text-align: center;
   }
}
.type-tab-text {
   color: rgba(15, 25, 26, 0.5) !important;
   text-decoration: none !important;
   @include font-dmsans;
   font-family: "DM Sans", sans-serif !important;
   font-size: 1.1rem !important;
   cursor: pointer !important;
   min-height: none !important;
   text-transform: none !important;
   border-radius: 0.5rem !important;
   padding: 0.2rem 3rem !important;
}

.type-tab-text-selected {
   color: #fff !important;
   background-color: transparent !important;
   z-index: 1 !important;
   min-height: none !important;
}
.orders-page {
   background-color: #f5f5f9;
}
