.category-item-name {
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #0f191a;
  text-transform: capitalize;
}

.category-item-container {
  display: flex;
  align-items: center;
  padding: 8%;
  justify-content: space-between;
  background-color: #fff;
cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.category-active {
  background-color: #e7e8e9;
}