@import 'assets/fonts/fonts.scss';

.QRContainer {
  background-color: #fff;
  min-height: 100%;
  padding: 2%;
  border-radius: 10px;
}

.tab-text {
  color: rgba(15, 25, 26, 0.5)!important;
  text-decoration: none!important;
  font-weight: 600!important;
  @include font-dmsans;
  font-family: 'DM Sans', sans-serif!important;
  font-size: 1.3rem!important;
  height: 2.7rem!important;
  cursor: pointer!important;
  text-transform: none!important;
}
.tab-text-selected {
  color: #2D6B4D!important;
}

.QRHeader {
  @include font-dmsans;
  font-weight: bold;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  // background-color: grey;
}

.QRMainContainer {
  display: flex;
  align-items: center;
  padding: 2%;
  width: fit-content;
  border-radius: 10px;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  gap: 0.5rem;
}

.QRCardWrapper {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 2%;
  gap: 1.8rem;
  flex-wrap: wrap;
  // background-color: pink;
}

.QRCodeBtnMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  //  background-color: aqua;
}


.qr-codes-title-text {
  cursor: pointer;

  &:hover {
    color: grey;
    text-decoration-line: underline;
  }
}

.qr-codes-title-row {
  // background-color: grey;
  display: flex;
  flex-direction: column;
  width: 20vw;
  margin-top: 1.5rem;
}

.QR-header-container {
  // background-color: grey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.qr-code-mainContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  // background-color: red;
}