@import 'assets/fonts/fonts.scss';

.ChipContainer {
  display: flex;
  padding: 3% 5% 3% 5%;
  border: 1px solid rgba(15, 25, 26, 0.25);
  gap: 0.5rem;
  border-radius: 10px;
  margin-bottom: 3%;
  cursor: pointer;
  margin-right: 3%;
  color: rgba(15, 25, 26, 0.25);
}
.ChipTitle {
  font-size: 1.1rem;
  font-weight: 400;
  @include font-dmsans;
  text-align: center;
  white-space: nowrap;
}

.VegActive {
  background-color: #cadad2;
  border-color: #2d6b4d;
  color: #000;
}
.NonVegActive {
  border-color: #d90429;
  background-color: #f5c0c9;
  color: #000;
}
.ChipSwitchWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 0.6rem;
}