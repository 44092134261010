/** @format */

.custom-switch {
   .ant-switch-inner-checked {
      font-size: 10px;
   }
   .ant-switch-inner-unchecked {
      color: black;
   }
}
.offersCardInputWrapper {
   display: flex;
   align-items: center;
   gap: 10px;
}
.offersCardBtnWrapper {
   display: flex;
   justify-content: center;
   gap: 20px;
   height: 100%;
}
