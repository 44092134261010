
#close {
    aspect-ratio: 1 / 1;
    width: 3rem;
    border: none;
    background-color: #fff;
}
#side-wrapper-edit {
    transition: transform 0.5s ease-in, backdrop-filter 0.5s 0.5s ease-out;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
}
#side-wrapper-edit.open { 
    transform: translateX(0%);
    backdrop-filter: brightness(0.5);
}
#side-wrapper-edit.close { 
    transform: translateX(100%);
    backdrop-filter: brightness(1);
    
}
#order-side-edit {
    height: 100vh;
    background-color: #fff;
    border: none;
    width: 45vw;
}
#padded-edit {
    padding: 0rem 2rem;
    overflow-y: scroll;
}
#items-table-edit {
    tr {
        border: none;
    }

    > thead {
        > tr {
            border: 1px solid #C3C5C6;
            border-width: 1px 0px;
        }
    }
}
.dg-btn{
    background-color: #2D6B4D;
    color: #fff;
    border: 1px solid #2D6B4D;
}
.b-btn-h {
    background-color: #ECEFFD;
    color: #4361EE;
    border: none;
    border-radius: 0.3rem;
    padding: 0.5rem;
}
.r-btn-h {
    background-color: #fff;
    color: #D90429;
    border: 1px solid #D90429;
    border-radius: 0.3rem;
}
.r-btn-h2 {
    background-color: #FBE6EA;
    color: #D90429;
    border: 1px solid #D90429;
    border-radius: 0.3rem;
    padding: 0.5rem;
}
.g-btn {
    background-color: #2FBF71;
    border: 1px solid #2FBF71;
    color: #fff;
    border-radius: 0.3rem;
    padding: 0.5rem;
}
#qty-edit{
    color: #2D6B4D;
    font-weight: bold;
    line-height: .9rem;

    > :first-child {
        border-radius: 0.3rem 0 0 0.3rem;
    }
    > :nth-child(2) {
        padding: .1rem .7rem;
    }
    > :last-child {
        border-radius: 0 0.3rem 0.3rem 0;
    }
}