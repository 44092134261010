@import 'assets/fonts/fonts.scss';

.food-item-container {
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 2% 3% 2% 3%;
  gap: 0.5rem;
  border-radius: 5px;
}
.food-item-image-wrapper {
  width: 4.5rem;
  height: 3.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.food-item-image-logo {
  background-color: rgba(230, 57, 70, 0.1);
  height: 100%;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: rgba(230, 57, 70, 1);
}
.food-item-image {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.food-item-right-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.food-item-top-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.food-item-name {
  width: 70%;
  font-size: 1rem;
  font-weight: bold;
  color: #0f191a;
}

.food-item-price {
  width: 30%;
  font-size: 0.9rem;
  font-weight: bold;
  color: #0f191a;
}
.food-item-btns {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.food-item-text {
  width: 90%;
  word-wrap: break-word;
  font-size: 0.8rem;
  color: rgba(15, 25, 26, 0.5);
}

.food-item-first-row-container {
  display: flex;
}

.food-item-text-see-more {
  font-weight: 400;
  font-size: 13.6px;
  color: #2fbf71;
}
@media only screen and (max-width: 890px) {

  .food-item-container {

}
}