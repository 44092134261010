/** @format */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
* {
   font-family: "DM Sans", sans-serif;
}
// #tablediv {
//     transition: all 0.5s ease-in;
// }
// #tablediv.open {
//     transform: scale(1)
// }
// #tablediv.close {
//     transform: scale(0)
// }
#tablediv {
   background-color: #fff;
   height: 95vh;
   overflow: hidden;
   text-transform: none;
}
#tabletop > input,
#tabletop button {
   border: 1px solid #c3c5c6;
   outline: none;
   background-color: #fff;
   color: black;
   padding: 0.3rem 1rem;
   border-radius: 0.4rem;
}
#tabletop > input {
   border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
   border-left: none;
   width: 60%;
   display: flex;
   flex-wrap: wrap;
}
#mg {
   border-top-right-radius: 0px !important;
   border-bottom-right-radius: 0px !important;
   border-right: none !important;
}
#table-wrapper {
   border: 1px solid #c3c5c6;
}
.tbodyContainer {
   overflow: scroll;
   height: 80%;
   scroll-behavior: smooth;
   -ms-overflow-style: none;
   scrollbar-width: none;
   // background-color: red;
}
.tbodyContainer::-webkit-scrollbar {
   display: none;
}
thead tr,
tbody tr {
   border-bottom: 1px solid #c3c5c6;
}
td a {
   text-decoration: none;
}
th,
th button {
   color: #878c8c;
   background-color: #fff;
   font-weight: normal;
   border: none;
   outline: none;
}
.tHeadContainer {
   position: sticky;
   top: 0;
}
thead tr th,
tbody tr td {
   padding: 0.5rem 0px;
   > button {
      padding: 0.3rem 0.5rem;
      border-radius: 0.2rem;
      border: none;
      outline: none;
      background-color: #fff;
      color: #4361ee;
      font-weight: lighter;
   }
}
tr:hover {
   background-color: #f5f5f9;
}
label {
   color: #878c8c;
}
.Dine-in {
   color: #4361ee;
}

.Manual {
   color: green;
}
.Delivery {
   color: #e63946;
}
.Take {
   color: #f7b801;
}
td > span,
#order-status label,
#payment label {
   padding: 0.3rem 0.5rem;
   border-radius: 0.2rem;
}

.newOrder {
   background-color: rgba(235, 94, 40, 0.1);
   border: 1px solid #eb5e28;
   color: #eb5e28;
   padding: 0.5rem 2.3rem;
}
.orderConfirmed {
   background-color: rgba(209, 60, 164, 0.1);
   color: #d13ca4;
   border: 1px solid #d13ca4;
   padding: 0.5rem 1rem;
}
.orderUpdated {
   padding: 0.5rem 1rem;
   background-color: rgba(122, 32, 72, 0.1);
   border: 1px solid #7a2048;
   color: #7a2048;
}
.orderDelivered {
   padding: 0.5rem 1rem;
   background-color: rgba(20, 20, 20, 0.1);
   border: 1px solid #141414;
   color: #141414;
}
.startPreparation {
   padding: 0.5rem 1rem;
   background-color: rgba(111, 45, 189, 0.1);

   border: 1px solid #046515;
   color: #046515;
   color: #6f2dbd;
   border: 1px solid #6f2dbd;
}
.orderCompleted {
   padding: 0.5rem 1rem;
   border: 1px solid #2fbf71;
   background-color: #eaf9f1;
   color: #2fbf71;
}
.orderDispatched {
   padding: 0.5rem 1rem;
   color: #b2a108;
   border: 1px solid #b2a108;
   background-color: rgba(238, 238, 127, 0.1);
}

.form-check-label {
   white-space: nowrap;
}
span {
   text-transform: capitalize;
}
.Paid,
.Paid label {
   color: #2fbf71;
   background-color: #eaf9f1;
}
.Un-Paid,
.Un-Paid label {
   color: #d90429;
   background-color: #f2dee1;
}
#filters-toggle::after {
   display: none;
}
#filter-date::after {
   display: none;
}
#tabletop svg {
   color: #878c8c;
}
.dropdown-menu {
   min-width: 12rem;
}
.dropdown-menu div {
   margin-bottom: 0.5rem;
}
.orders-no-items-container {
   position: fixed;
   width: 75%;
   height: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
}
.orders-header-btn-wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.refresh-btn {
   display: flex;
   align-items: center;
   justify-content: center;
   color: #4361ee;
   font-weight: 500;
   font-size: 1.2rem;
   height: 100%;
   gap: 0.3rem;
   cursor: pointer;
}

.order-header-refresh-btn {
   display: flex;
   justify-content: center;
   align-items: baseline;
   gap: 0.5rem;
   flex-direction: row;
}

.refresh-info {
   text-transform: none;
   font-size: 14px;
}
.refresh-time {
   width: 20px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
   padding: 0 9% 0 5%;
   white-space: nowrap;
}

.buttons-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 1rem;
   margin-bottom: 1rem;
}
