/** @format */

.dine-in-order-card-main-container {
   position: absolute;
   width: 41vw;
   aspect-ratio: 5/2.2;
   overflow: scroll;
   overflow-x: hidden;
   display: flex;
   flex-direction: column;
   -ms-overflow-style: initial;
   scrollbar-width: 5px;
   padding: 0 1% 0 0;
}
.dine-in-order-card-header {
   padding: 2%;
   width: 100%;
   border-radius: 8px;
   color: #2d6b4d;
   font-weight: 600;
   display: flex;
   justify-content: space-between;
   gap: 3%;
   align-items: center;
}
.dine-in-order-card-header-order {
   display: flex;
   gap: 1rem;
   width: 10%;
}
.dine-in-status {
   height: 25px;
   display: flex;
   align-items: center;
   font-size: 14px;
   border-radius: 2px;
   white-space: nowrap;
   padding: 2%;
   font-weight: 500;
   border: none;
}
.dine-in-order-card-table {
   width: 100%;
   border: 1px solid #96b5a6;
   border-radius: 8px;
   margin-top: 2%;
}
.dine-in-order-card-btn-wrapper {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   gap: 0.5rem;
   padding: 1%;
   margin-bottom: 1%;
}
.dine-in-order-items-container {
   aspect-ratio: 5/2.5;
   overflow: scroll;
   overflow-x: hidden;
   display: flex;
   flex-direction: column;
   -ms-overflow-style: initial;
   scrollbar-width: 5px;
   padding: 0 2% 0 0;
}
.dine-in-order-card-main-container::-webkit-scrollbar {
   scroll-behavior: smooth;
   display: block;
   position: absolute;
   scrollbar-width: 5px;
   width: 10px;
}

.add-on-tag {
   border-radius: 8px;
   border: 1px solid var(--other-color-edit-100, #4361ee);
   font-size: 0.6rem;
   color: #4361ee;
   padding-inline: 0.4rem;
   padding-block: 0.1rem;
   background-color: #4362ee10;
}

.customization-tag {
   @extend .add-on-tag;
   color: #f35b04;
   border-color: #f35b04;
   color: #f35b04;
   background-color: #f35c040e;
}

/* Track */
.dine-in-order-card-main-container::-webkit-scrollbar-track {
   background: #e7e8e8;
}

/* Handle */
.dine-in-order-card-main-container::-webkit-scrollbar-thumb {
   background: #7b8181;
}

/* Handle on hover */
