.DisputeContainer {
  padding: 5%;
  background-color: #fff;
  border-radius: 8px;
  height: auto;
}
.DisputeFormGroup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
