.NotificationsWindow {
    position: absolute;
    top: 100%;
    right: 0;
    width: 25rem;
    background-color: #fff;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    backdrop-filter: blur(5px);
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
    padding: 1rem;
    gap: 0.5rem;
    font-weight: normal;
    font-size: 1rem;
    line-height: normal;
    transition: transform 0.3s ease-in-out;
    // border: 1px solid #000;
}
.NotificationsWindow__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #EEF5FF;
    mix-blend-mode: multiply;
    z-index: 99;
}
.NotificationsWindow__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bolder;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: #000;
    padding: 0.5rem 0;
}
button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
}
.mark-as-read {
    font-size: 1rem;
    color: #2C74E3;
    cursor: pointer;
}
.Notification {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    padding: 1rem 0;
    border-bottom: 1px solid #E5E5E5;
    border-radius: 0.5rem;
}
.Notification:hover {
    background-color: #F5F5F5;
}

.read-icon {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    margin: 0 0.5rem;
    aspect-ratio: 1/1;
}
.Notification__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // white-space: nowrap;
    // background-color: grey;
    width: 90%;
}
.Notification__content__sender-time {
    color: #858585;
    font-size: 0.8rem;
}
.Notification__icon {
    margin-left: auto;
}
.Notification__icon__image {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #E5E5E5;
}
.view-all-notifications {
    font-size: 1rem;
    color: #2C74E3;
    cursor: pointer;
    margin-top: 1rem;
    margin-left: auto;
}
.blue {
    background-color: #2C74E3;
}
.transparent {
    background-color: transparent;
}
.closed {
    // display: none;
    transform: translateY(-50%) translateX(50%) scale(0);
}
.opened {
    display: flex;
    transform: translateY(0) translateX(0) scale(1);
}