.SubscriptionEndedModal {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: brightness(0.75);
    z-index: 1000;

    .ModalContent {
        background-color: #fff;
        min-height: 200px;
        color: #D90429;
        border-radius: 0.8rem;
    }
    a {
        color: #fff;
        background-color: #2D6B4D;
        border-radius: 0.3rem;
        padding: 1rem 2em;
        text-decoration: none;
    }
}

@media screen and (max-width: 768px) {
    .SubscriptionEndedModal {
        .ModalContent {
            width: 70%;
            border-radius: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .SubscriptionEndedModal {
        .ModalContent {
            width: 50%;
        }
    }
}

@media screen and (min-width: 1024px) {
    .SubscriptionEndedModal {
        .ModalContent {
            width: 40%;
        }
    }
}

@media screen and (max-width: 480px) {
    .SubscriptionEndedModal {
        .ModalContent {
            width: 90%;
        }
    }
}
